import React from 'react'
import CompanyPreview from './company-preview'
import Filter from './filter'
import Search from './search'
import Container from '../components/container'
import BlockContent from '../components/block-content'

import styles from './company-grid.module.css'

function CompanyGrid (props) {
  // console.log("Body: ", props.body);
  return (
    <div className={styles.root} id='next'>
      <Container>
        <div className={styles.titleBar}>
          <div className={styles.subtitle}>
            <BlockContent blocks={props.body} />
          </div>
          <div>
            <div className={styles.filters} data-company-filters>
              {props.sectors && (
                <Filter
                  title='Sectors'
                  filter='company'
                  options={props.sectors}
                />
              )}
              {props.stages && (
                <Filter
                  title='Stage'
                  filter='company'
                  options={props.stages}
                />
              )}
            </div>
            <Search />
          </div>
        </div>
      </Container>

      <Container size='large'>
        <div className={`${styles.grid}`} data-display-items>
          {props.nodes &&
            props.nodes.map(node => (
              <CompanyPreview key={node._id} {...node} />
            ))}
        </div>
      </Container>
    </div>
  )
}

CompanyGrid.defaultProps = {
  nodes: []
}

export default CompanyGrid
