import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'
import Figure from './figure'
import Slideshow from './slideshow'
import Video from '../video/video'

import typography from '../typography.module.css'

const serializers = {
  types: {
    block (props) {
      switch (props.node.style) {
        case 'h1':
          return <h1 className={typography.responsiveTitle1}>{props.children}</h1>

        case 'h2':
          return <h2 className={typography.responsiveTitle2}>{props.children}</h2>

        case 'h3':
          return <h3 className={typography.responsiveTitle3}>{props.children}</h3>

        case 'h4':
          return (
            <div className={typography.responsiveTitle4}>
              <h4 className={typography.responsiveTitle4Text}>{props.children}</h4>
              <div className={typography.responsiveTitle4Fill}></div>
              <p className={typography.responsiveTitle4Dot}>.</p>
            </div>
        )

        default:
          if (props.children.length === 1 && typeof props.children[0] === 'string' && props.children[0].trim() === '') {
            return '';
          } else {
            return <p className={typography.paragraph}>{props.children}</p>
          }
      }
    },
    figure (props) {
      return <Figure {...props.node} />
    },
    slideshow (props) {
      return <Slideshow {...props.node} />
    },
    embedCode (props) {
      return <></>
    }
  },
  marks: {
    link: ({mark, children}) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark
      return blank ?
        <a href={href.replace('http://', 'https://')} target="_blank" rel="noopener">{children}</a>
        : <a href={href.replace('http://', 'https://')}>{children}</a>
    }
  }
}

const BlockContent = ({ blocks }) => <BaseBlockContent blocks={blocks} serializers={serializers} />

export default BlockContent
